export default {
	schemaType: "template",
	displayName: "Alumni Detail",
	component: "AlumniDetail",
	dataPacks: ["ALUMNI"],
	type: { label: "ALUMNI", value: "ALUMNI", mode: "detail" },
	content: [
		{
			title: "Title",
			type: "HeadingField",
			key: "title",
			default: { tag: "h2", content: "Title" },
			options: [
				{ value: "h1", label: "H1" },
				{ value: "h2", label: "H2" },
				{ value: "h3", label: "H3" },
				{ value: "h4", label: "H4" },
				{ value: "span", label: "span" },
			],
			advanced: true,
			hideable: true,
			humanReadable: true,
		},
		{
			title: "Detail",
			type: "TextField",
			key: "detail",
			hideable: true,
			humanReadable: true,
		},
		{
			title: "Delete text",
			type: "Wysiwyg",
			humanReadable: true,
			key: "deleteText",
			mandatory: true,
		},
		{
			title: "Delete text go to alumni list",
			type: "TextField",
			key: "detailGoToList",
			hideable: true,
			humanReadable: true,
		},
		{
			title: "Button",
			type: "ComponentContainer",
			whiteList: ["Button"],
			key: "buttonGoToList",
			hideable: true,
			humanReadable: true,
		},
	],

	default: {
		type: "template",
		templateType: "AlumniDetail",
		title: {
			content:
				"Ya puedes completar tu perfil para compartir en la comunidad de Alumni Garrigues",
			tag: "h1",
		},
		detail:
			"Podrás conectar con exalumnos que enfrentaron los mismos desafíos mientras estudiaban en el Centro de Estudios Garrigues y conocer sus experiencias profesionales actuales. Nos preocupamos por tu privacidad, permitiéndote elegir qué información mostrar u ocultar en tu perfil público.",
		deleteText: `<p>Puedes eliminar tu cuenta poniéndote en contacto con los administradores del sitio enviando un correo electrónico a <a href="mailto:administradores.site@centrogarrigues.com">administradores.site@centrogarrigues.com</a>.</p>`,
		detailGoToList: "Busca y conecta con antiguos compañeros",
		buttonGoToList: {
			component: "Button",
			buttonText: "Link",
			appearance: "primary",
			size: "medium",
			url: {
				href: "",
				linkTo: null,
				newTab: false,
				noFollow: false,
			},
		},
	},
	// TODO: Add thumbnails
	thumbnails: {},
};
