import { basicModuleList } from "@schemas/modules";

export default {
	schemaType: "template",
	displayName: "Product Landing",
	component: "ProductHeroForm",
	dataPacks: ["LANDINGS"],
	type: { label: "Landings", value: "landings", mode: "detail" },
	content: [
		{
			title: "Hero Section",
			type: "ComponentArray",
			maxItems: 1,
			whiteList: ["ProductHeroForm", "HeroEcommerce"],
			key: "heroSection",
		},
		{
			title: "Salesforce ID",
			type: "TextField",
			key: "salesforceID",
			mandatory: true,
			helptext: "Structured data",
		},
		{
			title: "Main Section",
			type: "ComponentArray",
			maxItems: null,
			whiteList: [...basicModuleList],
			key: "mainSection",
		},
		{
			title: "Phone",
			type: "TextField",
			key: "phoneNumber",
			hideable: true,
		},
	],
	default: {
		type: "template",
		templateType: "ProductHeroForm",
		phoneNumber: "+34 91 514 53 30",
		heroSection: {
			component: "Section",
			name: "Hero Section",
			modules: [
				{
					component: "ProductHeroForm",
					title: { content: "Title", tag: "h2" },
					description:
						"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
					quickFacts: {
						component: "QuickFacts",
						elements: [
							{
								component: "QuickFactsElement",
								title: "Lorem Ipsum",
								description: "Lorem ipsum dolor ",
							},
							{
								component: "QuickFactsElement",
								title: "Lorem Ipsum",
								description: "Lorem ipsum dolor ",
							},
							{
								component: "QuickFactsElement",
								title: "Lorem Ipsum",
								description: "Lorem ipsum dolor ",
							},
							{
								component: "QuickFactsElement",
								title: "Lorem Ipsum",
								description: "Lorem ipsum dolor ",
							},
						],
					},
					backgroundImage: {
						component: "Image",
						alt: "Alt",
						file: {
							publicId:
								"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
						},
						veil: 0,
					},
				},
			],
		},
		mainSection: {
			component: "Section",
			name: "Main Section",
			modules: [],
		},
	},
	thumbnails: {},
};
