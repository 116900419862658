export default {
	STUDY_TYPE: {
		title: "Study Type",
		dataPacks: ["STUDY", "JOB_OFFERS", "ALUMNI"],
		local: false,
		taxonomy: false,
		fromPage: false,
		translate: true,
		schema: {
			fields: [
				{
					title: "Title",
					type: "TextField",
					humanReadable: true,
					key: "title",
					from: "title",
				},
				{
					title: "Form type (degree/master/executive)",
					type: "TextField",
					humanReadable: true,
					key: "formType",
					from: "formType",
				},
				{
					title: "Order",
					type: "NumberField",
					key: "order",
					from: "order",
					indexable: true,
				},
			],
		},
		clone: null,
		defaultValues: null,
	},
};
