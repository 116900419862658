import * as React from "react";

const AlumniDetail = React.lazy(() =>
	import(
		"./AlumniDetail" /* webpackChunkName: "template---src-AlumniDetail-js" */
	)
);

const AlumniList = React.lazy(() =>
	import("./AlumniList" /* webpackChunkName: "template---src-AlumniList-js" */)
);

const BasicTemplate = React.lazy(() =>
	import(
		"./BasicTemplate" /* webpackChunkName: "template---src-BasicTemplate-js" */
	)
);

const Error404 = React.lazy(() =>
	import("./Error404" /* webpackChunkName: "template---src-Error404-js" */)
);

const Sitemap = React.lazy(() =>
	import("./Sitemap" /* webpackChunkName: "template---src-Sitemap-js" */)
);

const StaffDetail = React.lazy(() =>
	import(
		"./StaffDetail" /* webpackChunkName: "template---src-StaffDetail-js" */
	)
);

const StaffList = React.lazy(() =>
	import("./StaffList" /* webpackChunkName: "template---src-StaffList-js" */)
);

const EventDetail = React.lazy(() =>
	import(
		"./EventDetail" /* webpackChunkName: "template---src-EventDetail-js" */
	)
);

const EventList = React.lazy(() =>
	import("./EventList" /* webpackChunkName: "template---src-EventList-js" */)
);

const NewsList = React.lazy(() =>
	import("./NewsList" /* webpackChunkName: "template---src-NewsList-js" */)
);

const MultiproductHeroForm = React.lazy(() =>
	import(
		"./MultiproductHeroForm" /* webpackChunkName: "template---src-MultiproductHeroForm-js" */
	)
);

const ProductHeroForm = React.lazy(() =>
	import(
		"./ProductHeroForm" /* webpackChunkName: "template---src-ProductHeroForm-js" */
	)
);

const EventHeroForm = React.lazy(() =>
	import(
		"./EventHeroForm" /* webpackChunkName: "template---src-EventHeroForm-js" */
	)
);

const NewsDetail = React.lazy(() =>
	import("./NewsDetail" /* webpackChunkName: "template---src-NewsDetail-js" */)
);

const StudyDetail = React.lazy(() =>
	import(
		"./StudyDetail" /* webpackChunkName: "template---src-StudyDetail-js" */
	)
);

const AwardDetail = React.lazy(() =>
	import(
		"./AwardDetail" /* webpackChunkName: "template---src-AwardDetail-js" */
	)
);

const StudyList = React.lazy(() =>
	import("./StudyList" /* webpackChunkName: "template---src-StudyList-js" */)
);

const AdmissionRequestFiles = React.lazy(() =>
	import(
		"./AdmissionRequestFiles" /* webpackChunkName: "template---src-AdmissionRequestFiles-js" */
	)
);
// const BlogDetail = React.lazy(() =>
//   import(
//     './BlogDetail/BlogDetailv2' /* webpackChunkName: "template---src-BlogDetail-BlogDetailv2-js" */
//   )
// )
// const BlogDetailBackwardCompatibility = React.lazy(() =>
//   import(
//     './BlogDetail/BlogDetailBackwardCompatibility' /* webpackChunkName: "template---src-BlogDetail-BlogDetailBackwardCompatibility-js" */
//   )
// )
const BlogDetail = React.lazy(() =>
	import("./BlogDetail" /* webpackChunkName: "template---src-BlogDetail-js" */)
);
// const BlogListBackwardCompatibility = React.lazy(() =>
//   import(
//     './BlogList/BlogListBackwardCompatibility' /* webpackChunkName: "template---src-BlogListBackwardCompatibility-js" */
//   )
// )
// const BlogList = React.lazy(() =>
//   import(
//     './BlogList/BlogListFiltered' /* webpackChunkName: "template---src-BlogList-BlogListFiltered-js" */
//     )
// )
const BlogList = React.lazy(() =>
	import("./BlogList" /* webpackChunkName: "template---src-BlogList-js" */)
);

const JobOfferDetail = React.lazy(() =>
	import(
		"./JobOfferDetail" /* webpackChunkName: "template---src-JobOfferDetail-js" */
	)
);
const JobOfferList = React.lazy(() =>
	import(
		"./JobOfferList" /* webpackChunkName: "template---src-JobOfferList-js" */
	)
);
const LegalTemplate = React.lazy(() =>
	import(
		"./LegalTemplate" /* webpackChunkName: "template---src-LegalTemplate-js" */
	)
);

// Landings Portugal
const LandingInfoPortugal = React.lazy(() =>
	import(
		"./LandingInfoPortugal" /* webpackChunkName: "template---src-LandingInfoPortugal-js" */
	)
);

const LandingEasyPackPortugal = React.lazy(() =>
	import(
		"./LandingEasyPackPortugal" /* webpackChunkName: "template---src-LandingEasyPackPortugal-js" */
	)
);

const LandingEventPortugal = React.lazy(() =>
	import(
		"./LandingEventPortugal" /* webpackChunkName: "template---src-LandingEventPortugal-js" */
	)
);
const LandingTMGEventPortugal = React.lazy(() =>
	import(
		"./LandingTMGEventPortugal" /* webpackChunkName: "template---src-LandingTMGEventPortugal-js" */
	)
);

const Modal = React.lazy(() =>
	import("./Modal" /* webpackChunkName: "template---src-Modal-js" */)
);

const Quizz = React.lazy(() =>
	import("./Quizz" /* webpackChunkName: "template---src-Quizz-js" */)
);

export {
	AlumniDetail,
	AlumniList,
	BasicTemplate,
	Error404,
	Sitemap,
	StaffList,
	EventList,
	NewsList,
	StaffDetail,
	EventDetail,
	MultiproductHeroForm,
	ProductHeroForm,
	EventHeroForm,
	NewsDetail,
	StudyDetail,
	AwardDetail,
	StudyList,
	AdmissionRequestFiles,
	BlogDetail,
	BlogList,
	JobOfferDetail,
	JobOfferList,
	LegalTemplate,
	Modal,
	Quizz,

	// Landings Portugal
	LandingInfoPortugal,
	LandingEasyPackPortugal,
	LandingEventPortugal,
	LandingTMGEventPortugal,
};
