export default {
	languageNames: {
		en: "English",
		es: "Spanish",
		pt_PT: "Portuguese",
		ca: "Catalan",
	},
	hello: "Hello",
	ui: {
		breadcrumb: {
			home: "Home",
		},
		pagination: {
			error: "Sorry, no content is currently available.",
			previous: "Previous",
			next: "Next",
			loading: "Loading...",
		},
		eventInfo: {
			when: "When",
			where: "Where",
			hour: "Hour",
			eventType: "Event type",
		},
	},
	form: {
		header: {
			request: "Request Information",
			lightBoxRequest:
				"Fill in the form with your details to request information",
			requestLong:
				"Please, fill in the form to request information about the programme",
			req: "Information Request",
			reqSub: "Fill the form to request information about the program",
			register: "Register",
			subtitle: "Leave us your details and we'll contact you shortly",
			text: "PENDING TO TRANSLATION",
		},
		confirmation: {
			error: {
				title:
					"There was an error sending the form, sorry for the inconvenience.",
				subtitle: "The number is not valid, please try again.",
				detail: "Please try again later.",
				errorData: "Please check that your information is correct",
			},
			success: {
				title: "Thanks for submitting the form!",
				subtitle: "We will contact you soon.",
				admissionDetail:
					"You have successfully completed your application for admission.",
				admissionSubtitle:
					"We have received the documents provided and we will contact you shortly with additional information.",
				admissionAttachDetail:
					"We have received your request to attach later the necessary documentation to formalise your application.",
				admissionAttachSubtitle:
					"We will shortly send you an email with a link to the page where you can send us the necessary documentation.",
				okTitle: "Thank you",
				ok: "The information has been successfully submitted.",
			},
		},
		fields: {
			label: {
				name: "Name",
				lastName: "Last name",
				email: "Email",
				contactEmail: "Email",
				phone: "Phone",
				landlineNumber: "Landline number",
				courseType: "Course type",
				courseDefault: "Course",
				academicStatus: "How do you intend to apply?",
				country: "Nationality",
				companyCountry: "Company country",
				companyName: "Company name",
				schoolName: "Name of the School",
				institution: "Institution where you studied",
				documentNumber: "Document number",
				courseProperties: {
					location: "On campus",
					courseMode: "Regimen",
					knowledgeArea: "Area",
					course: "Course",
					programType: "Course type",
				},
				teamName: "",
				participants: "",
				participantName: "",
				participantLastName: "",
				participantEmail: "",
				participantPhone: "",
				participantCountry: "",
				participantSchool: "",
				participantSchoolYear: "",
				academicDocuments: "Academic documents",
				academicTranscript: "Academic transcript (max. 2 MB)",
				selectFile: "Select file",
				professionalDocuments: "Company information",
				curriculumVitae: "Curriculum vitae (max. 2 MB)",
				coverLetter: "Cover letter (max. 2 MB)",
				send: "Send",
				department: "Department",
				jobTitle: "Job title",
				labelWantToGoWithInformationRequest:
					"There are no scholarships available",
				wantToGoWithInformationRequest:
					"Do you want to proceed with the request for information?",
				hasCompletedSecondaryEducation: "Have you finished secondary school?",
				yearCompletedSecondaryEducation:
					"What year did you finish secondary school?",
				yearCompletedSecondaryEducationSuperior:
					"Date of completion of higher education",
				indicateYourAccessExam: "Indicate your access exam.",
				takingAnyOfTheseExams:
					"dicate if you will be taking any of these exams.",
				accessExamsOptions: {
					abiturGermany: {
						label: "Abitur - Germany",
						value: "Abitur - Alemanhã",
					},
					europeanBAC: {
						label: "European BAC",
						value: "BAC Europeu",
					},
					cambridgeAssessmentInternationalEducation: {
						label: "Cambridge Assessment International Education",
						value: "Cambridge Assessment Interna- tional Education",
					},
					enem: {
						label: "Enem",
						value: "Enem",
					},
					internationalStudentStatus: {
						label: "International Student Status",
						value: "Estatuto do Estudante Internacional",
					},
					USAAPExams: {
						label: "EUA — AP Exams",
						value: "EUA — AP Exams",
					},
					USASATExams: {
						label: "EUA — SAT Exams",
						value: "EUA — SAT Exams",
					},
					portugueseNationalExams: {
						label: "Portuguese National Exams",
						value: "Exames Nacionais Portugueses",
					},
					internationalBaccalaureateDiploma: {
						label: "International Baccalaureate Di- ploma (IB Diploma)",
						value: "International Baccalaureate Di- ploma (IB Diploma)",
					},
					unitedKingdomGCE: {
						label: "United Kingdom - GCE",
						value: "Reino Unido — GCE",
					},
					others: {
						label: "Others",
						value: "Outros",
					},
				},
				wantStartClasses: "When do you want to start classes?",
				yesOption: "Yes",
				noOption: "No",
				regime: "Modality*",
				haveBachelorDegree: "Do you have a bachelor degree?",
			},
			error: {
				generic: "This field is required",
				phone: "Please enter a valid number",
				email: "Please enter a valid e-mail address",
				name: "Please enter your name",
				lastName: "Please enter your last name",
				selectFile: "Please choose a file",
				fileFormat:
					"Please enter a document with a valid extension pdf, doc, docx",
				fileSize: "Please, the file cannot be larger than 2 MB",
				datePickerStart: "To submit this form you have to be at least  ",
				datePickerFinish: " years old",
				birthday: "Date of birth is required",
				yearFinishSecondarySchool:
					"The year of completion of secondary school is compulsory",
				number: "Please enter a valid number",
			},
			tmg: {
				participant: "Participant",
				buttonNext: "Next",
				buttonPrevious: "Back",
			},
		},
		legalLink: "Privacy Policy",
		legalText:
			"I authorize the data collected here to be used, in accordance with the ",
		legalText2:
			" of ENSILIS, to contact me to find out more about the University's products, services and events, via email, messaging apps, telephone or SMS. By clicking and proceeding, I declare that I am 16 years of age or older.",
		legalText3:
			"The data submitted using this form will be processed by the data controller — CENTRO EUROPEO DE ESTUDIOS Y FORMACIÓN EMPRESARIAL GARRIGUES SLP (hereinafter ‘Centro de Estudios Garrigues’) — for the purpose of managing this request, administering the various activities for which you submit your data, and sending marketing materials and information about Centro de Estudios Garrigues activities that may be of interest to you by post, phone, or electronic means (email, SMS, instant message, etc). The basis for the processing of the personal data provided under this request is the development and fulfilment of the relationship formalised with the data subject, as well as the fulfilment of Centro de Estudios Garrigues’ legal obligations and the data subject´s unequivocal consent. The data provided using this form will be included in an automated file controlled by Centro de Estudios Garrigues, whose address for this purpose is Avenida de Fernando Alonso 8, 28108 Alcobendas (Madrid). In the absence of a valid statement to the contrary, the data subject expressly consents to the total or partial automated processing of such data for the time necessary to fulfil the aforementioned purposes. The owner of the data has the right to access, rectify and cancel the data, to restrict and oppose to its processing, and to exercise the right of the portability of personal data, free of charge, as detailed in the full privacy policy available at <a href='https://www.centrogarrigues.com/en/privacy-policy'>https://www.centrogarrigues.com/en/privacy-policy</a>. You may withdraw your consent to receive marketing or promotional communications at any time by contacting the data controller at Avenida de Fernando Alonso 8, 28108 Alcobendas (Madrid) or by sending an email to <a href='mailto:dpo@centrogarrigues.com'>dpo@centrogarrigues.com/a> with the subject line 'Withdrawal of marketing consent'.",
		button: "Send",
		inscribirme: "Register",
		checkboxText: "I want to receive information about the program",
		yes: "Yes",
		no: "No",
		yearSelect: "School year",
		birthdate: "Date of birth",
		schoolYearValue1: "12th year/ 3rd year",
		schoolYearValue2: "11th year/ 2nd year",
		schoolYearValue3: "10th year/ 1st year",
		schoolYearValue4: "Other",
		easyPackDate: "Expected date of arrival in Portugal",
		tipodedocumento: {
			label: "Type of document",
			types: {
				NIF: "NIF",
				Pasaporte: "Passport",
				TjtaResd: "Residence card",
				NIE: "NIE",
				CIF: "CIF",
				DocExt: "Doc. Ext.",
			},
		},
		chooseAnAnswer: "Choose an answer",
		sendFiles: "Send files",
		attachmentsFormIntro:
			"On this page you will be able to attach the necessary documents to formalise your admission.",
		consent:
			"I agree to receive commercial communications about products and services from my own or third parties based on the evolution of my profile and behaviour, through internal and external sources.",
		attachPhoto: "Attach picture (max 1 MB, 80x80px)",
		personalDocuments: "Personal documents",
		personalDocDefaultName: "NIF, NIE, CIF (max. 2 MB)",
		complaint: {
			error: "Select a query type",
			label: "Type of inquiry",
			enquiryOptions: {
				complaint: "Complaint",
				suggestion: "Suggestion",
				claim: "Claim",
			},
		},
		imageUseConsent: `I authorize ENSILIS to use, reproduce and disseminate my name, image
and/or voice (obtained through photographs, videos or recordings) of events
in which I participate, in a physical environment or on digital platforms, for
the organization and dissemination of events, institutional presentations,
conferences, fairs and stands.`,
		year_finish_secondary_school: "Completion of secondary school",
		date_generic_error: "The date is mandatory",
	},
	button: {
		viewMore: "View more",
		readMore: "Read more",
		subscribe: "Subscribe",
		register: "Register",
		downloadBrochure: "Download Brochure",
		filters: "Filters",
		seeResults: "See results",
	},
	academicStatus: {
		st01: "I want to apply with the higher education exams of my country",
		st02: "I have a bachelor's degree completed",
		st03: "Technological Specialization Course Diploma (CET)",
		st04: "Other",
		st05: "I want to do the internal exams in the institution of destination",
		st06: "Professional Experience",
		st07: "I'm over 23 years old and want to do the exams in the institution of destination",
		st08: "I'm studying at an university in my country and want to transfer",
		st09: "Re-entry",
		st10: "Technological Specialization Diploma (Scientific Area)",
		st11: "Technological Specialization Diploma (Other Area)",
		st12: "PEPA",
		st13: "Professional Higher Technical Courses Diploma (CTeSP)",
		st14: "Degree Holder (Doc Pending)",
		st15: "Student in an emergency situation for humanitarian reasons",
		st16: "Proof of Aptitude for Distance Learning",
		in10YearComplete: "In 10th Year Complete",
		in11YearComplete: "In 11th Year Complete",
		in9AnoCompleto: "In 9th Year Complete",
		ceseCet: "CESE / CET",
		postgraduate: "Postgraduate",
	},
	filters: {
		filterBy: "Filter by",
		filters: "Filters",
		clearFilters: "Delete filters",
		seeResults: "See results",
		study: {
			programType: "Program type",
			courseMode: "Course mode",
			location: "Location",
			language: "Language",
			knowledgeArea: "Knowledge area",
			promotionYear: "Promotion year",
			program: "Program",
		},
	},
	jobOffers: {
		seeOffer: "See offer",
		location: "Location",
		languages: "Languages",
		degree: "Degree",
		available: "available offers",
		filteredSingular: "Showing $1 offer of $2 available",
		filteredPlural: "Showing $1 offers of $2 available",
	},
	blog: {
		by: "by",
		author: "Author",
		category: "Category",
		tag: "Tag",
		allCategories: "All categories",
		available: "$1 articles",
		filteredSingular: "Showing $1 article of $2",
		filteredPlural: "Showing $1 articles of $2",
		articlesFromThisAuthor: "Articles from this author",
		seeAllFromAuthor: "See all articles from this author",
		publishedAt: "Published originally on",
		otherArticles: "Other articles",
		article: "Article",
		backToPostList: "Back to post list",
	},
	studyList: {
		available: "$1 results",
		filteredSingular: "Showing $1 result of $2",
		filteredPlural: "Showing $1 results of $2",
		location: "Location",
		languages: "Languages",
		knowledgeArea: "Area",
		programType: "Program Type",
	},
	btnViewMore: {
		error: "Sorry, no content is currently available.",
		progress: "You have seen $1 of $2 results",
		more: "View more",
		loading: "Loading...",
	},
	moreInfo: "More information",
	quickFacts: {
		location: "Location",
		courseMode: "Course Mode",
		language: "Language",
		start: "Start",
		dedication: "Dedication",
		credits: "ECTS",
		learningOption: "Learning option",
	},
	selector_regimen: {
		onsite: "Presencial",
		online: "Online",
	},
	formCommon: {
		titulacionesRealizadas: "Academic qualifications",
		continuar: "Next",
		direcciNPostal: "Address",
		seleccionePais: "Select country (*)",
		pais: "Country",
		seleccioneRegion: "Select region (*)",
		campoRegionEsObligatorio: "Field region is mandatory.",
		localidad: "City",
		introduceLocalidad: "Introduce location.",
		codigoPostal: "Postal Code (*)",
		introduceCodigoPostal: "Introduce postal code.",
		domicilio: "Address",
		introduceDomicilio: "Introduce address.",
		cMoHasConocidoElCentroD:
			"How did you find out about Centro de Estudios Garrigues?",
		datosPersonalesYDeContacto: "Personal details and contact information.",
		opcional: "(optional)",
		datosPersonales: "Personal details",
		datosAcademicos: "Education",
		documentacion: "Academic qualifications",
		solicitudFinalizada: "Application completed",
		datosDeInteres: "Additional info",
		idiomas: "Languages",
		adjuntarDocumentos: "Attach documents",
		adjuntareLosDocumentosMasTarde: "I will attach the documents later.",
		datosProfesionales: "Professional documents",
		consienteCheckboxText1: "I consent to",
		consienteCheckboxText2:
			"the processing of my personal data for marketing purposes",
		consiente:
			"I consent to the processing of my personal data for marketing purposes.",
		level: "Level",
	},
	formAdmision: {
		inscribete: "Sign up",
		solicitar: "Apply for admission",
		espana: "Spain",
		fotocopiaEscaneadaDeDniOD:
			"Scanned copy of your national identity card or equivalent identification document.",
		expedienteAcadMico: "Academic transcript.",
		currCulumVitae: "Curriculum vitae (for executive programmes only)",
		cartaDeMotivaciNEnElCas:
			"Cover letter (if you are applying for financial aid)\n",
		alCumplimentarEstaSolicitud:
			"By filling in this application form, you will be starting the admission process to study at Centro de Estudios Garrigues. If applicable, we will get in touch to invite you to sit the admission tests.",
		paraRealizarLaSolicitudCor:
			"For your application to be successful, you will need to attach a series of documents on the last page of this form. We are listing them below so that you can have them ready.",
		volver: "Previous",
		seleccionaElTipoDePrograma:
			"Select the type of programme in which you are interested:",
		estSInteresadoEnNuestroP:
			"Are you interested in our Academic Excellence Scholarships and Study Grants programme?",
		podrSOptarANuestroProgram:
			'You will be eligible for our Academic Excellence Scholarships and Study Grants programme as long as you have an academic record with a minimum average mark of "B" (8) and you are in the final year of your degree or have completed your studies in the two years prior to applying.',
		si: "Yes",
		no: "No",
	},
	formAward: {
		estudiosRealizados: "Studies you have carried out",
		centroDeEstudios: "Educational institution",
		notaMedia: "Average grade",
		anoDeInicio: "Start year",
		porFavorIntroduzcaAnoDeInicio: "Please enter start year (numbers only).",
		anoDeFin: "End year",
		porFavorIntroduzcaAnoDeFin: "Please enter the end year (numbers only).",
		hemosRecibidoCorrectamente:
			"The information has been successfully submitted for ",
		muchasGraciasPorInscribirte: "Thank you",
		yaEstasRegistrado: "You are already registered.",
		hemosDetectadoQueYaTeHasInscritoAnteriormente:
			"We have detected that you have already registered before.",
		enviar: "Send",
		indicarRespuesta: "Indicate answer.",
	},
	formInfo: {
		title: "Contact us",
		requestInfoText:
			"To request information about our studies, you can do it through the Request information form. If you wish to begin the admission process, you can do so by filling in the Request admission form. You can also call us on ",
		solicitar: "Apply for admission",
		ok: "",
		labelSubject: "Subject",
		errorSubject: "Enter the subject of the message",
		labelEmail: "Email",
		errorEmail: "Enter a valid email address",
		labelMessage: "Message",
		errorMessage: "Enter the message",
		sendBtn: "Send",
	},
	eventForm: {
		typeOfAssistance: "Por favor elige un tipo de asistencia",
		labelEmail: "Email",
		errorEmail: "Introduce un email válido",
		selectProgram: "Por favor elige programa en el que está interesado",
		sendBtn: "Send",
		jpa: {
			academicSituation: {
				title: "Academic situation",
				options: {
					eso: { value: "", label: "PENDING TRANSLATION" },
					bachelor1: {
						value: "",
						label: "PENDING TRANSLATION",
					},
					bachelor2: {
						value: "",
						label: "PENDING TRANSLATION",
					},
					over25: {
						value: "",
						label: "PENDING TRANSLATION",
					},
					graduate: {
						value: "",
						label: "PENDING TRANSLATION",
					},
					fp1: {
						value: "",
						label: "PENDING TRANSLATION",
					},
					fp2: {
						value: "",
						label: "PENDING TRANSLATION",
					},
					sameStudies: {
						value: "",
						label: "PENDING TRANSLATION",
					},
					differentStudies: {
						value: "",
						label: "PENDING TRANSLATION",
					},
				},
			},
			companions: "Companions",
			parentsAttend: {
				title: "Parents attend",
				options: {
					yes: "Yes",
					no: "No",
				},
			},
		},
	},
	dates: {
		years: {
			singular: "year",
			plural: "years",
		},
		months: {
			singular: "month",
			plural: "months",
		},
		weeks: {
			singular: "week",
			plural: "weeks",
		},
		days: {
			singular: "day",
			plural: "days",
		},
	},
	download: "download",
	pricing: "pricing",
	precario: "preçário",
	soon: "Coming soon",
	invalidDate: "Invalid Date",
	orIfYouPrefer: "Or if you prefer",
	alumni: {
		viewProfile: "View profile",
		deleteImage: "Delete",
		replaceImage: "Change",
		uploadPicture: "Upload picture",
		cancel: "Cancel",
		save: "Save changes",
		goToAlumniList: "Go to Alumni list",
		deleteAccount: "Delete Account",
		searchAlumni: "Search for alumni",
		backToSite: "Back to Centro de Estudios Garrigues",
		completeProfile: "Complete my profile",
		label: {
			qualification: "Qualification",
			biography: "Biography",
			personalData: "Personal data",
			professionalData: "Professional data",
			contactData: "Contact Data",
		},
		form: {
			fields: {
				label: {
					city: "City",
					linkedln: "Linkedln",
				},
				error: {
					city: "City field is mandatory",
					linkedln: "Linkedln field is mandatory",
				},
			},
			altDefaultImage: "Upload image",
			LightboxTitle: "Choose your profile picture",
			LightboxSubTitle: "Valid formats: jpeg, jpg, png. Maximum size 2MB.",
		},
	},
};
