export default {
	ALUMNI_PROGRAM: {
		title: "Alumni Program",
		dataPacks: ["ALUMNI"],
		local: true,
		taxonomy: false,
		fromPage: false,
		translate: true,
		edited: true,
		schema: {
			fields: [
				{
					title: "Title",
					type: "TextField",
					humanReadable: true,
					key: "title",
				},
				{
					title: "Program type",
					type: "AsyncCheckGroup",
					source: "ALUMNI_PROGRAM_TYPE",
					key: "programType",
					from: "programType",
				},
			],
		},
		clone: null,
		defaultValues: null,
	},
};
