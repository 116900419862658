import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "component",
	displayName: "Image",
	component: "Image",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "File",
					type: "ImageField",
					key: "file",
				},
				{
					title: "Title",
					type: "TextField",
					key: "title",
				},
				{
					title: "Alternative Text",
					type: "TextField",
					key: "alt",
				},
				{
					title: "Veil percentage",
					type: "SliderField",
					key: "veil",
				},
			],
		},
	],
	default: {
		component: "Image",
	},
	thumbnails: {
		"1x": "/thumbnails/components/Image/thumbnail.png",
		"2x": "/thumbnails/components/Image/thumbnail@2x.png",
	},
});
