export default {
	ALUMNI_PROGRAM_AND_YEAR: {
		title: "Alumni Program and Year",
		dataPacks: ["ALUMNI"],
		local: true,
		taxonomy: false,
		fromPage: false,
		translate: true,
		edited: true,
		schema: {
			fields: [
				{
					title: "Program and Promotion",
					type: "TextField",
					humanReadable: true,
					key: "title",
				},
				{
					title: "Program Type",
					type: "AsyncCheckGroup",
					source: "ALUMNI_PROGRAM_TYPE",
					key: "programType",
				},
				{
					title: "Program",
					type: "AsyncCheckGroup",
					source: "ALUMNI_PROGRAM",
					key: "program",
				},
				{
					title: "Promotion Year",
					type: "AsyncCheckGroup",
					source: "ALUMNI_PROMOTION_YEAR",
					key: "programPromotionYear",
				},
			],
		},
		clone: null,
		defaultValues: null,
	},
};
