export default {
	ALUMNI: {
		title: "Alumni",
		dataPacks: ["ALUMNI"],
		local: true,
		taxonomy: false,
		fromPage: false,
		translate: true,
		editable: true,
		//		private: true,
		schema: {
			fields: [
				{
					title: "Title",
					type: "TextField",
					key: "title",
				},
				{
					title: "Alumni name",
					type: "TextField",
					key: "name",
				},
				{
					title: "Username",
					type: "TextField",
					key: "username",
				},
				{
					title: "Image",
					type: "ImageField",
					key: "image",
					mandatory: true,
				},
				{
					title: "Location",
					type: "TextField",
					humanReadable: true,
					key: "location",
				},
				{
					title: "Bio",
					type: "Wysiwyg",
					humanReadable: true,
					key: "bio",
				},
				{
					title: "Company",
					type: "TextField",
					key: "company",
				},
				{
					title: "Position",
					type: "TextField",
					key: "position",
				},
				{
					title: "Personal email",
					type: "TextField",
					key: "personal_email",
				},
				{
					title: "Personal phone",
					type: "TextField",
					key: "personal_phone",
				},
				{
					title: "Personal phone prefix",
					type: "TextField",
					key: "personal_phone_prefix",
				},
				{
					title: "terms of use adopted?",
					key: "accepted_terms_of_use",
					type: "CheckGroup",
					note: "This field will be filled in by the Alumni upon first login and acceptance of the terms of use.",
					options: [
						{
							value: true,
							title: "Yes",
							name: "yes",
						},
						{
							value: false,
							title: "No",
							name: "no",
						},
					],
					mandatory: true,
					required: true,
				},
				{
					title: "Linkedin link",
					type: "TextField",
					key: "linkedin_link",
				},
				{
					title: "Program Type",
					type: "AsyncCheckGroup",
					source: "ALUMNI_PROGRAM_TYPE",
					key: "programType",
				},
				{
					title: "Program",
					type: "AsyncCheckGroup",
					source: "ALUMNI_PROGRAM",
					key: "program",
				},
				{
					title: "Program and year",
					type: "AsyncCheckGroup",
					source: "ALUMNI_PROGRAM_AND_YEAR",
					key: "programPromotionYear",
				},
				{
					title: "Promotion Year",
					type: "AsyncCheckGroup",
					source: "ALUMNI_PROMOTION_YEAR",
					key: "promotionYear",
				},
			],
		},
		clone: null,
	},
};
