export default {
	ALUMNI_PROGRAM_TYPE: {
		title: "Alumni Program Type ",
		dataPacks: ["ALUMNI"],
		local: true,
		taxonomy: false,
		fromPage: false,
		translate: true,
		edited: true,
		schema: {
			fields: [
				{
					title: "PROGRAM TYPE",
					type: "TextField",
					humanReadable: true,
					key: "title",
				},
			],
		},
		clone: null,
		defaultValues: null,
	},
};
