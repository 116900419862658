export default {
	ALUMNI_PROMOTION_YEAR: {
		title: "Alumni Promotion Year ",
		dataPacks: ["ALUMNI"],
		local: true,
		taxonomy: false,
		fromPage: false,
		translate: true,
		edited: true,
		schema: {
			fields: [
				{
					title: "Title",
					type: "TextField",
					key: "title",
				},
				{
					title: "Starting year",
					type: "TextField",
					key: "startingDate",
				},
				{
					title: "Ending year",
					type: "TextField",
					key: "endingYear",
				},
			],
		},
		clone: null,
		defaultValues: null,
	},
};
