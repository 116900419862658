import TitleAndSubtitle from "@schemas/commons/TitleAndSubtitle";

export default {
	schemaType: "template",
	displayName: "Alumni List",
	component: "AlumniList",
	dataPacks: ["ALUMNI"],
	type: { label: "Alumni", value: "ALUMNI", mode: "list" },
	content: [
		{ ...TitleAndSubtitle.content[0], key: "title" },
		{ ...TitleAndSubtitle.content[1], key: "subtitle" },
		{
			title: "Alumni Distributor",
			type: "ReferenceField",
			sources: [{ structuredData: "ALUMNI" }],
			key: "data",
		},
		{
			title: "Items per page",
			type: "NumberField",
			key: "itemsPerPage",
			mandatory: true,
		},
		{
			title: "Filters",
			type: "CheckGroup",
			key: "filters",
			hideable: true,
			options: [
				{
					value: "ALUMNI_PROGRAM_TYPE",
					name: "ALUMNI_PROGRAM_TYPE",
					title: "Program type",
				},
				{
					value: "ALUMNI_PROGRAM",
					name: "ALUMNI_PROGRAM",
					title: "Program",
				},
				{
					value: "ALUMNI_PROMOTION_YEAR",
					name: "ALUMNI_PROMOTION_YEAR",
					title: "Promotion Year",
				},
			],
		},
	],

	default: {
		type: "template",
		templateType: "AlumniList",
		title: { content: "Title", tag: "h2" },
		subtitle: "Lorem Ipsum",
		data: {
			mode: "auto",
			sources: [{ structuredData: "ALUMNI" }],
			quantity: 0,
		},
		hasDistributorData: true,
		itemsPerPage: 12,
		filters: "",
	},

	thumbnails: {},
};
