import { instance } from "@instance-config";
const isUDDI = instance.includes("UDDI");

export default {
	QUICK_FACTS: {
		title: "Quick Facts",
		dataPacks: ["LANDINGS", "STUDY_LANDING"],
		local: false,
		taxonomy: false,
		fromPage: false,
		translate: true,
		editable: isUDDI,
		schema: {
			fields: [
				{
					title: "Title",
					type: "TextField",
					humanReadable: true,
					key: "title",
				},
				{
					title: "Key",
					type: "TextField",
					key: "key",
				},
			],
		},
		clone: null,
		defaultValues: null,
	},
};
