import { basicModuleList } from "@schemas/modules";

export default {
	schemaType: "template",
	displayName: "Event Landing",
	component: "EventHeroForm",
	dataPacks: ["LANDINGS"],
	type: { label: "Landings", value: "landings", mode: "detail" },
	content: [
		{
			title: "Hero Section",
			type: "ComponentArray",
			maxItems: 1,
			whiteList: ["EventHeroForm"],
			key: "heroSection",
		},
		{
			title: "Main Section",
			type: "ComponentArray",
			maxItems: null,
			whiteList: ["LandingForm", ...basicModuleList],
			key: "mainSection",
		},
		{
			title: "Phone",
			type: "TextField",
			key: "phoneNumber",
			hideable: true,
		},
		{
			title: "Event Type",
			type: "RadioGroup",
			key: "eventType",
			options: [
				{
					title: "HyFliex",
					name: "hyFliex",
					value: "Presencial, Online",
				},
				{
					title: "Online",
					name: "online",
					value: "Online",
				},
				{
					title: "In person",
					name: "presencial",
					value: "Presencial",
				},
			],
		},
		{
			title: "Form Type",
			type: "ConditionalField",
			key: "conditionalForm",
			mandatory: true,
			options: [
				{
					value: true,
					title: "Generic Event",
					name: "genericEvent",
				},
				{
					value: false,
					title: "JPA",
					name: "JPA",
				},
			],
			fields: [
				{
					title: "Campaign ID",
					type: "TextField",
					key: "uECRM",
					mandatory: true,
					condition: true,
				},
				{
					title: "Programs List",
					type: "ReferenceField",
					sources: [{ structuredData: "STUDY_LANDING" }],
					key: "programsList",
					mandatory: true,
					condition: false,
				},
			],
		},
	],
	default: {
		type: "template",
		templateType: "EventHeroForm",
		phoneNumber: "+34 91 514 53 30",
		conditionalForm: true,
		uECRM: "",
		eventType: "Presencial, Online",
		programsList: {
			mode: "manual",
			sources: [{ structuredData: "STUDY_LANDING" }],
			fixed: [],
		},
		heroSection: {
			component: "Section",
			name: "Hero Section",
			modules: [
				{
					component: "EventHeroForm",
					title: { content: "Title", tag: "h2" },
					description:
						"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",

					conditionalFormQuick: true,
					conditionalForm: true,
					uECRM: "",
					eventType: "Presencial, Online",
					programsList: [],
					quickFacts: {
						component: "QuickFacts",
						elements: [
							{
								component: "QuickFactsElement",
								title: "Lorem Ipsum",
								description: "Lorem ipsum dolor ",
							},
							{
								component: "QuickFactsElement",
								title: "Lorem Ipsum",
								description: "Lorem ipsum dolor ",
							},
							{
								component: "QuickFactsElement",
								title: "Lorem Ipsum",
								description: "Lorem ipsum dolor ",
							},
							{
								component: "QuickFactsElement",
								title: "Lorem Ipsum",
								description: "Lorem ipsum dolor ",
							},
						],
					},

					backgroundImage: {
						component: "Image",
						alt: "Alt",
						file: {
							publicId:
								"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
						},
						veil: 0,
					},
				},
			],
		},

		mainSection: {
			component: "Section",
			name: "Main Section",
			modules: [],
		},
	},

	thumbnails: {},
};
