import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { instance } from "@instance-config";
import createThemeStyles from "@schemas/commons/getStyles";
import { createSchema } from "@utils/";

export default createSchema({
	schemaType: "module",
	displayName: "Highlighted Download",
	component: "HighlightedDownload",
	category: "interactive",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "HeadingField",
					humanReadable: true,
					key: "title",
					advanced: true,
					hideable: true,
					default: { tag: "h2", content: "Title" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
				},
				{
					title: "Subtitle",
					type: "RichText",
					humanReadable: true,
					key: "subtitle",
					hideable: true,
				},
				{
					title: "Button",
					type: "ComponentContainer",
					whiteList: ["Button"],
					key: "button",
					hideable: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Style",
					key: "theme",
					type: "VisualUniqueSelection",
					mandatory: true,
					options: [
						{
							...createThemeStyles("garrigues-theme", [
								"accentAlt",
								"accentLight",
							]),
						},
						{ ...createThemeStyles("eeg-theme", ["default", "defaultAlt"]) },
						{ ...createThemeStyles("ipam-theme", ["default", "defaultAlt"]) },
						{ ...createThemeStyles("iade-theme", ["default", "defaultAlt"]) },
						{ ...createThemeStyles("iade-es-theme", ["defaultAlt"]) },
						{ ...createThemeStyles("uddi-theme", ["defaultAlt"]) },
					],
					columns: 8,
				},
			],
		},
	],

	default: {
		component: "HighlightedDownload",
		title: { content: "Title", tag: "h3" },
		subtitle: "Lorem Ipsum",
		button: {
			component: "Button",
			buttonText: "Descargar",
			appearance: "primary",
			size: "small",
			url: {
				href: "",
				linkTo: null,
				newTab: false,
				noFollow: false,
			},
		},
		theme: instance.includes("CEG") ? "accentAlt" : "defaultAlt",
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/HighlightedDownload@1x.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/HighlightedDownload@x2.png`,
	},
});
